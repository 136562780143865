import { Stack, Title, Container, Box, Text } from "@mantine/core";
import { PrivacySection } from "./components/PrivacySection";
import { ContactSection } from "./components/ContactSection";
import { PRIVACY_SECTIONS } from "./data/privacyContent";

export const PrivacyPolicyPage = () => (
  <Container size="lg">
    <Stack gap="xl" py="xl">
      {/* Hero Section */}
      <Box>
        <Title
          ta="center"
          style={{
            fontSize: "3rem",
            marginBottom: "2rem",
            color: "var(--purple)",
          }}
        >
          Privacy Policy
        </Title>
        <Text size="sm" ta="center" c="dimmed" mb="xl">
          Last Updated: June 24, 2024
        </Text>
        <Box
          style={{
            padding: "2rem",
            borderRadius: "15px",
            background: "var(--glass-bg)",
            backdropFilter: "var(--glass-blur-sm)",
            border: "1px solid var(--purple-15)",
            marginBottom: "3rem",
          }}
        >
          <Text
            size="lg"
            ta="center"
            style={{ maxWidth: "800px", margin: "0 auto" }}
          >
            Welcome to Puzzlit, the social media puzzle app that brings joy to
            your day! At Puzzlit, we are dedicated to protecting your privacy
            and ensuring the security of your data.
          </Text>
        </Box>
      </Box>

      {/* Privacy Sections */}
      <Box>
        {PRIVACY_SECTIONS.map((section) => (
          <PrivacySection key={section.title} {...section} />
        ))}
      </Box>

      {/* Contact Section */}
      <ContactSection />
    </Stack>
  </Container>
);

export default PrivacyPolicyPage;
