import { Burger, Menu, Box } from "@mantine/core";
import { Colors } from "../../styles/colors";
import { NavigationLinks } from "./NavigationLinks";
import { SocialLinks } from "./SocialLinks";

type MobileMenuProps = {
  opened: boolean;
  onToggle: () => void;
  showMainLinks: boolean;
};

export const MobileMenu = ({
  opened,
  onToggle,
  showMainLinks,
}: MobileMenuProps) => (
  <Box style={{ position: "relative" }}>
    <Menu
      opened={opened}
      position="bottom-end"
      offset={5}
      transitionProps={{
        transition: "scale-y",
        duration: 200,
      }}
      shadow="md"
      width={200}
      zIndex={1000}
      withArrow={false}
      closeOnClickOutside={true}
    >
      <Menu.Target>
        <Box
          onClick={(e) => {
            e.stopPropagation();
            onToggle();
          }}
          style={{ cursor: "pointer" }}
        >
          <Burger
            opened={opened}
            color={Colors.WHITE}
            aria-label="Toggle navigation"
          />
        </Box>
      </Menu.Target>

      <Menu.Dropdown
        style={{
          backgroundColor: "var(--glass-bg)",
          backdropFilter: "var(--glass-blur-lg)",
          border: `1px solid var(--purple-15)`,
          padding: "1rem 0",
          borderRadius: "12px",
          boxShadow: `0 8px 32px rgba(0, 0, 0, 0.2), 0 0 15px var(--purple-10)`,
          overflow: "hidden",
        }}
      >
        <Box style={{ marginBottom: "1rem" }}>
          <NavigationLinks
            onLinkClick={onToggle}
            variant="menu"
            showOnlyAdditional={!showMainLinks}
          />
        </Box>
        <SocialLinks variant="menu" />
      </Menu.Dropdown>
    </Menu>
  </Box>
);
