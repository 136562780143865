export const BETA_TESTERS = [
  "Trevor Belinsky",
  "Tracie Catlett",
  "Colin McMahon",
  "Matthew Wyatt",
  "Lauren Hayden",
  "Anthony Labruna",
  "Anita Chen",
  "Justin Gelwicks",
  "Ben Hodson-Walker",
  "Mark Catlett",
  "Will Catlett",
  "Eric Osterman",
  "Ethan Kozden",
  "Graham Schwartz",
  "Emily B",
  "Everette Taylor",
  "George Hayden",
  "Karen Hayden",
  "Stazja Stuccio",
];
