import { Box, Text } from "@mantine/core";
import EmailText from "../../../components/common/EmailText";

export const ContactSection = () => (
  <Box
    style={{
      padding: "2rem",
      borderRadius: "15px",
      background: "var(--purple-10)",
      border: "1px solid var(--purple-20)",
      marginTop: "3rem",
    }}
  >
    <Text mb="md">
      By using Puzzlit, you acknowledge that you have read, understood, and
      agreed to these Terms and Conditions.
    </Text>
    <Text>
      Puzzlit LLC | <EmailText />
    </Text>
  </Box>
);
