import { Box, Title, List } from "@mantine/core";
import { Colors } from "../../../styles/colors";

type StandardSectionProps = {
  title: string;
  items: string[];
};

export const StandardSection = ({ title, items }: StandardSectionProps) => (
  <Box
    style={{
      padding: "2rem",
      borderRadius: "15px",
      background: "var(--glass-bg)",
      backdropFilter: "var(--glass-blur-sm)",
      border: "1px solid var(--purple-15)",
      marginBottom: "2rem",
    }}
  >
    <Title
      order={3}
      mb="xl"
      style={{
        color: "var(--purple2)",
      }}
    >
      {title}
    </Title>

    <List
      type="unordered"
      styles={{
        item: {
          color: "var(--white)",
          opacity: 0.9,
        },
      }}
    >
      {items.map((item) => (
        <List.Item key={item}>{item}</List.Item>
      ))}
    </List>
  </Box>
);
