export type PrivacySection = {
  title: string;
  description?: string;
  subsections?: Array<{
    subtitle: string;
    items: string[];
  }>;
  items?: string[];
};

export const PRIVACY_SECTIONS: PrivacySection[] = [
  {
    title: "Information We Collect",
    description:
      "We collect different types of information to provide you with a seamless Puzzlit experience. This information can be categorized as follows:",
    subsections: [
      {
        subtitle: "Data You Provide to Us",
        items: [
          "Account Creation Data: To create an account, we require specific information, including your username, phone number, and a password.",
          "Profile Information: You may choose to enhance your Puzzlit experience by providing additional information such as a display name and profile photo.",
        ],
      },
      {
        subtitle: "Data We Collect When You Use Our Services",
        items: [
          "Content Data: Puzzlit is all about puzzles and challenges. We collect data related to the puzzles you solve, your progress, and any interactions within the app. This does not include photos or videos.",
          "Usage Data: We gather information about your interactions with our app, such as the puzzles you solve, your puzzle preferences, and the time spent on various puzzles.",
        ],
      },
    ],
  },
  {
    title: "How We Use Your Information",
    description:
      "We value your privacy and utilize your data carefully and thoughtfully to provide you with a better Puzzlit experience.",
    items: [
      "Operation and Improvement of Services: We use your data to create and manage your account, display puzzles, and enhance your experience within the app.",
      "Communication: We may contact you for app-related communications, such as important updates, announcements, or responding to your inquiries.",
      "Research and Analytics: We may use data for research, analysis, and statistical purposes to improve and refine our services.",
      "Security: We employ your data to ensure the security and integrity of Puzzlit, as well as to comply with relevant laws and regulations.",
    ],
  },
  {
    title: "When We Share Your Information",
    description:
      "Your data is shared only when it is necessary to provide you with Puzzlit services or as required by law. Here are the circumstances under which we share your information:",
    items: [
      "Service Providers: We work with trusted service providers to deliver and enhance our services. These providers may have access to your data to assist in technical support, analytics, or other service-related tasks.",
      "Legal and Statutory Purposes: We may share data when required by law, such as in response to legal requests or to address issues like fraud or security concerns.",
      "Change in Ownership: In the event of a merger, acquisition, or sale of assets, your information may be shared, sold, or transferred to new ownership. This Privacy Policy will apply to any transferred data.",
    ],
  },
  {
    title: "Data Retention",
    description:
      "Our goal is to keep your information for only as long as it is necessary to provide Puzzlit services. Here is an overview of our data retention practices:",
    items: [
      "Account Creation Data: Retained for as long as your account is active. If you choose to delete your account, this information will be deleted.",
      "Content Data: Retained for as long as your account is active or until the content is deleted by you.",
      "Usage Data: Retained for no longer than 24 months.",
    ],
  },
  {
    title: "Your Controls",
    description:
      "At Puzzlit, we want you to have control over your data. Here are some controls you have:",
    items: [
      "Account Deletion: You can delete your account at any time through the app settings. If you do so, your account and associated information will be deleted. However, information may not be deleted if we are legally required to preserve it.",
      "Communication Preferences: You can choose your notification preferences through the app settings.",
    ],
  },
  {
    title: "Your Rights",
    description:
      "Your privacy is important, and you have certain rights over your data:",
    items: [
      "Access and Correction: You can request access to your data and correct it if it is inaccurate.",
      "Questions and Feedback: If you have any questions or feedback about our privacy practices, feel free to reach out to us.",
    ],
  },
];
