export type StandardSection = {
  title: string;
  description?: string;
  items: string[];
};

export const STANDARDS_SECTIONS: StandardSection[] = [
  {
    title: "Respectful Behavior",
    items: [
      "Respect Others: Treat fellow users with kindness and respect. Avoid derogatory, discriminatory, or hateful comments.",
      "No Harassment or Bullying: Harassment, threats, and bullying are strictly prohibited. This includes any form of intimidation or abuse.",
    ],
  },
  {
    title: "Appropriate Content",
    items: [
      "Family-Friendly: Puzzlit is a platform for all ages. Content that is sexually explicit, violent, or otherwise inappropriate for a general audience is not allowed.",
      "No Hate Speech: Content that promotes racism, bigotry, hatred, or violence against individuals or groups based on race, ethnicity, religion, disability, gender, age, or sexual orientation is prohibited.",
      "No Spam or Scams: Avoid posting repetitive, irrelevant, or promotional content. Do not engage in scams or fraudulent activities.",
    ],
  },
  {
    title: "Privacy and Security",
    items: [
      "Personal Information: Do not share personal information such as phone numbers, addresses, or financial information publicly. Respect the privacy of others.",
      "Private Accounts: Users can make their accounts private and must accept follow requests. Do not attempt to bypass this feature or harass users for access.",
    ],
  },
  {
    title: "Intellectual Property",
    items: [
      "Original Content: Only share content you own or have permission to use. Respect copyrights, trademarks, and other intellectual property rights.",
      "Credit Creators: If you share content created by others, give proper credit.",
    ],
  },
  {
    title: "Interactions and Engagement",
    items: [
      "Positive Engagement: Like and comment on posts in a positive and constructive manner. Negative or trolling comments are not tolerated.",
      "Report Violations: If you encounter content or behavior that violates these standards, use the reporting feature to notify our moderation team.",
    ],
  },
  {
    title: "Accountability",
    items: [
      "Follow the Rules: Adherence to these standards is mandatory. Violation of any community standard can result in actions ranging from warnings to account suspension or termination.",
      "Appeal Process: Users have the right to appeal moderation decisions. Appeals must be submitted within 14 days of the action.",
    ],
  },
  {
    title: "Updates to Standards",
    items: [
      "Continuous Improvement: These standards may be updated periodically. Users will be notified of significant changes and are expected to comply with the latest version.",
    ],
  },
]; 