import { TeamMemberProps } from "../types/team";

export const TEAM_MEMBERS: TeamMemberProps[] = [
  {
    name: "Matthew Hayden",
    role: "Co-Founder, Business Development",
    bio: "A puzzle enthusiast and entrepreneur with a background in industrial engineering and finance, graduated from Lehigh University in 2023. Matthew has always been at the forefront of the puzzle atmosphere fostering a passion for problem solving and even creating puzzles for his family from a young age.",
    imageUrl: "matt.png",
  },
  {
    name: "Evan Chen",
    role: "Co-Founder, Business Development",
    bio: "A puzzle enthusiast and entrepreneur with a background in industrial engineering and finance, graduated from Lehigh University in 2023.",
    imageUrl: "evan.png",
  },
  {
    name: "Christopher Banas",
    role: "Co-Founder, Software Development",
    bio: "A software engineer graduate from the Rochester Institute of Technology with a focus on frontend web & mobile development. As a lifelong student of technology, Chris enjoys learning about anything software related and world history.",
    imageUrl: "chris.png",
  },
  {
    name: "Patrick Curley",
    role: "Co-Founder, Software Development",
    bio: "A software engineer graduate from the Rochester Institute of Technology with a focus on game design.",
    imageUrl: "pat.png",
  },
  {
    name: "Jack Catlett",
    role: "Co-Founder, Branding",
    bio: "Artist/Designer with a background in Product Design and a recent graduate from Lehigh University.",
    imageUrl: "jack.png",
  },
];
