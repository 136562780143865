type AnimatedLogoProps = {
  largeScreen: boolean;
};

export const AnimatedLogo = ({ largeScreen }: AnimatedLogoProps) => (
  <div
    style={{
      width: "100%",
      display: "flex",
      justifyContent: "center",
      overflow: "visible",
    }}
  >
    <img
      src="logoText.png"
      style={{
        width: largeScreen ? "500px" : "400px",
        height: "auto",
        maxWidth: "90vw",
      }}
      alt="Puzzlit Logo"
    />
  </div>
);
