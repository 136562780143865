import { Stack, Title, Container, Box, Text } from "@mantine/core";
import { StandardSection } from "./components/StandardSection";
import { ContactSection } from "./components/ContactSection";
import { STANDARDS_SECTIONS } from "./data/standardsContent";

export const StandardsPage = () => (
  <Container size="lg">
    <Stack gap="xl" py="xl">
      {/* Hero Section */}
      <Box>
        <Title
          ta="center"
          style={{
            fontSize: "3rem",
            marginBottom: "2rem",
            color: "var(--purple)",
          }}
        >
          Community Standards
        </Title>
        <Box
          style={{
            padding: "2rem",
            borderRadius: "15px",
            background: "var(--glass-bg)",
            backdropFilter: "var(--glass-blur-sm)",
            border: "1px solid var(--purple-15)",
            marginBottom: "3rem",
          }}
        >
          <Text
            size="lg"
            ta="center"
            style={{ maxWidth: "800px", margin: "0 auto" }}
          >
            Welcome to Puzzlit! Our goal is to create a fun, engaging, and safe
            community where users can enjoy solving puzzles and sharing their
            achievements with friends. To maintain a positive environment, we
            ask all members to adhere to the following community standards.
          </Text>
        </Box>
      </Box>

      {/* Standards Sections */}
      <Box>
        {STANDARDS_SECTIONS.map((section) => (
          <StandardSection key={section.title} {...section} />
        ))}
      </Box>

      {/* Contact Section */}
      <ContactSection />
    </Stack>
  </Container>
);

export default StandardsPage;
