import { useForm } from "@mantine/form";
import { sendDiscordNotification } from "../../../utils/discord";

interface ContactFormValues {
  name: string;
  email: string;
  message: string;
}

export const useContactForm = () => {
  const form = useForm<ContactFormValues>({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
    },
  });

  const handleSubmit = async (values: ContactFormValues) => {
    try {
      await sendDiscordNotification({
        webhookUrl:
          "https://discord.com/api/webhooks/1310704264074629252/b2wcxoP3Eyjiw6UUrQ4eRVV7nYmYk0uULYvszYZvJCFNrPq29wounMfZhDqWRdhZUYLW",
        title: "Website-Contact Form Submission",
        fields: [
          {
            name: "Name",
            value: values.name,
          },
          {
            name: "Email",
            value: values.email,
          },
          {
            name: "Message",
            value: values.message,
          },
        ],
      });

      alert("Thank you for your message! We will get back to you soon.");
      form.reset();
    } catch (error) {
      console.error("Error sending message:", error);
      alert(
        "Sorry, there was an error sending your message. Please try again later."
      );
    }
  };

  return {
    form,
    handleSubmit,
  };
};
