import { Stack, Container } from "@mantine/core";
import { ContactHero } from "./components/ContactHero";
import { ContactForm } from "./components/ContactForm";

export const ContactPage = () => (
  <Container size="lg">
    <Stack gap="xl" py="xl">
      <ContactHero />
      <div>
        <ContactForm />
      </div>
    </Stack>
  </Container>
);

export default ContactPage;
