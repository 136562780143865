import { Box, Title, Text, List, Anchor } from "@mantine/core";
import { Colors } from "../../../styles/colors";
import { BETA_TESTERS } from "../data/credits";

export const CreditsSection = () => (
  <Box mt="xl">
    <Title
      style={{
        marginBottom: "2rem",
        textShadow: `0 0 15px var(--purple-20)`,
      }}
    >
      Credits
    </Title>
    <Text mb="xl">
      We would like to extend our deepest gratitude to everyone who has
      supported and contributed to the Puzzlit effort. This journey would not
      have been possible without your invaluable help and encouragement.
    </Text>

    <Box
      style={{
        padding: "2rem",
        borderRadius: "15px",
        background: "var(--glass-bg)",
        backdropFilter: "var(--glass-blur-sm)",
        border: "1px solid var(--purple-15)",
      }}
    >
      <Title order={4} mb="md">
        Beta Testers (Kickstarter Supporters)
      </Title>
      <List
        type="unordered"
        styles={{
          item: {
            color: Colors.WHITE,
            opacity: 0.9,
          },
        }}
      >
        {BETA_TESTERS.map((tester) => (
          <List.Item key={tester}>{tester}</List.Item>
        ))}
      </List>
    </Box>

    {/* Special Thanks section */}
    <Box mt="xl">
      <Title order={4} mb="md">
        Special Thanks
      </Title>
      <List
        styles={{
          item: {
            color: Colors.WHITE,
            opacity: 0.9,
          },
        }}
      >
        <List.Item>Joseph Wesselman: Development feedback</List.Item>
        <List.Item>Owen Fitzgerald: Puzzle support</List.Item>
      </List>
    </Box>

    {/* Tabler section */}
    <Box
      mt="xl"
      style={{
        padding: "2rem",
        borderRadius: "15px",
        background: "var(--purple-10)",
        border: "1px solid var(--purple-20)",
      }}
    >
      <Title order={4} mb="md">
        Tabler
      </Title>
      <Text>
        Thank you to{" "}
        <Anchor
          href="https://tabler.io"
          target="_blank"
          c={Colors.PURPLE2}
          underline="hover"
          className="text"
        >
          Tabler
        </Anchor>{" "}
        for allowing us to use their open source icon library within this site
        and our app.
      </Text>
    </Box>

    <Text mt="md" c="dimmed" ta="center">
      This is a living document. We will continue to update it to acknowledge
      everyone who has played a role in our ongoing journey.
    </Text>
  </Box>
);
