import { Box, Text } from "@mantine/core";
import EmailText from "../../../components/common/EmailText";

export const ContactSection = () => (
  <Box
    style={{
      padding: "2rem",
      borderRadius: "15px",
      background: "var(--purple-10)",
      border: "1px solid var(--purple-20)",
      marginTop: "3rem",
    }}
  >
    <Text mb="md">
      Contact: For any privacy-related inquiries, please contact our Privacy
      Support team at <EmailText />
    </Text>
    <Text mb="md">
      Thank you for using Puzzlit. We are committed to protecting your privacy
      and providing you with an enjoyable puzzle-solving experience. If you have
      any concerns or questions about your data and privacy, please do not
      hesitate to contact us.
    </Text>
    <Text>
      Puzzlit LLC | <EmailText />
    </Text>
  </Box>
);
