import { Stack, Title, Text, Container, Box } from "@mantine/core";
import { TeamSection } from "./components/TeamSection";
import { CreditsSection } from "./components/CreditsSection";

export const AboutPage = () => (
  <Container size="lg">
    <Stack gap="xl" py="xl">
      {/* Hero Section */}
      <Box>
        <Title
          ta="center"
          style={{
            fontSize: "3rem",
            marginBottom: "2rem",
            color: "var(--purple)",
          }}
        >
          Who We Are
        </Title>
        <Box
          style={{
            padding: "2rem",
            borderRadius: "15px",
            background: "var(--glass-bg)",
            backdropFilter: "var(--glass-blur-sm)",
            border: "1px solid var(--purple-15)",
            marginBottom: "3rem",
          }}
        >
          <Text
            size="lg"
            ta="center"
            style={{ maxWidth: "800px", margin: "0 auto" }}
          >
            We are a passionate team of puzzle lovers, tech enthusiasts, and
            young entrepreneurs dedicated to creating a unique and engaging
            puzzle platform. Our diverse backgrounds in app development, game
            design, and community building equip us with the skills and vision
            needed to bring Puzzlit to life.
          </Text>
        </Box>
      </Box>

      <TeamSection />
      <CreditsSection />
    </Stack>
  </Container>
);

export default AboutPage;
