type DiscordField = {
  name: string;
  value: string;
};

type DiscordWebhookOptions = {
  webhookUrl: string;
  title: string;
  fields: DiscordField[];
  color?: number;
};

export const sendDiscordNotification = async ({
  webhookUrl,
  title,
  fields,
  color = 0x9262f3,
}: DiscordWebhookOptions) => {
  const message = {
    embeds: [
      {
        title,
        color,
        fields,
        timestamp: new Date().toISOString(),
      },
    ],
  };

  const response = await fetch(webhookUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  });

  if (!response.ok) {
    throw new Error(`Discord webhook failed: ${response.status}`);
  }

  return response;
};
