import { Button, Group, Text } from "@mantine/core";
import { ReactComponent as AppleIcon } from "./AppleLogo.svg";
import { Colors } from "../../../../styles/colors";
import classes from "./DownloadButton.module.css";

export type DownloadButtonProps = {
  largeScreen: boolean;
};

export const DownloadButton = ({ largeScreen }: DownloadButtonProps) => (
  <Button
    component="a"
    href="https://apps.apple.com/us/app/puzzlit-puzzles-made-social/id6472233094"
    target="_blank"
    rel="noopener noreferrer"
    className={classes.downloadButton}
  >
    <Group gap={largeScreen ? "xl" : "md"} wrap="nowrap">
      <AppleIcon width={32} height={32} fill={Colors.WHITE} />
      <Group gap={4} wrap="nowrap">
        {largeScreen ? (
          <>
            <Text c={Colors.WHITE} size="lg" fw={500}>
              Download on the
            </Text>
            <Text c={Colors.PURPLE2} size="lg" fw={700}>
              App Store
            </Text>
          </>
        ) : (
          <Text c={Colors.PURPLE2} size="lg" fw={700}>
            App Store
          </Text>
        )}
      </Group>
    </Group>
  </Button>
);
