import { Box, Stack, Title } from "@mantine/core";
import { TeamMember } from "./TeamMember";
import { TEAM_MEMBERS } from "../data/team";

export const TeamSection = () => (
  <Box>
    <Title
      style={{
        marginBottom: "2rem",
        textShadow: `0 0 15px var(--purple-20)`,
      }}
    >
      Our Team
    </Title>
    <Stack gap="xl">
      {TEAM_MEMBERS.map((member) => (
        <TeamMember key={member.name} {...member} />
      ))}
    </Stack>
  </Box>
);
