import { Box, Text } from "@mantine/core";
import EmailText from "../../../components/common/EmailText";

export const ContactSection = () => (
  <Box
    style={{
      padding: "2rem",
      borderRadius: "15px",
      background: "var(--purple-10)",
      border: "1px solid var(--purple-20)",
      marginTop: "3rem",
    }}
  >
    <Text mb="md">
      By following these community standards, we can ensure that Puzzlit remains
      a fun, respectful, and safe place for everyone. Thank you for being a part
      of our community!
    </Text>
    <Text>
      Puzzlit LLC | <EmailText />
    </Text>
  </Box>
);
