import { Anchor } from "@mantine/core";
import { Colors } from "../../styles/colors";
import classes from "../../styles/animations.module.css";

export const EMAIL = "admin@puzzlitapp.com";

export const EmailText = () => (
  <Anchor
    className={classes.hoverUnderline}
    href={`mailto:${EMAIL}`}
    c={Colors.PURPLE2}
    underline="never"
  >
    {EMAIL}
  </Anchor>
);

export default EmailText;
