import { TextInput, Textarea, Button, Stack, Box } from "@mantine/core";
import { Colors } from "../../../styles/colors";
import { useContactForm } from "../hooks/useContactForm";

export const ContactForm = () => {
  const { form, handleSubmit } = useContactForm();

  return (
    <Box
      style={{
        padding: "2rem",
        borderRadius: "15px",
        background: "var(--glass-bg)",
        backdropFilter: "var(--glass-blur-sm)",
        border: "1px solid var(--purple-15)",
      }}
      maw={500}
      mx="auto"
    >
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <Stack>
          <TextInput
            required
            label="Name"
            placeholder="Your name"
            {...form.getInputProps("name")}
          />

          <TextInput
            required
            label="Email"
            placeholder="your@email.com"
            {...form.getInputProps("email")}
          />

          <Textarea
            required
            autosize
            label="Message"
            placeholder="What would you like to tell us?"
            {...form.getInputProps("message")}
          />

          <Button type="submit" bg={Colors.PURPLE}>
            Send Message
          </Button>
        </Stack>
      </form>
    </Box>
  );
};
