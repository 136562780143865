import { Box, Title, Text } from "@mantine/core";
import { Colors } from "../../../styles/colors";

type TermsSectionProps = {
  title: string;
  description?: string;
  subsections?: Array<{
    subtitle: string;
    content: string | JSX.Element;
  }>;
  content?: string | JSX.Element;
};

export const TermsSection = ({
  title,
  description,
  subsections,
  content,
}: TermsSectionProps) => (
  <Box
    style={{
      padding: "2rem",
      borderRadius: "15px",
      background: "var(--glass-bg)",
      backdropFilter: "var(--glass-blur-sm)",
      border: "1px solid var(--purple-15)",
      marginBottom: "2rem",
    }}
  >
    <Title
      order={3}
      mb="xl"
      style={{
        color: "var(--purple2)",
      }}
    >
      {title}
    </Title>

    {description && <Text mb="xl">{description}</Text>}
    {content && <Text mb="xl">{content}</Text>}

    {subsections?.map(({ subtitle, content }) => (
      <Box key={subtitle} mb="xl">
        <Title order={4} mb="md">
          {subtitle}
        </Title>
        <Text>{content}</Text>
      </Box>
    ))}
  </Box>
);
