import { Box, Title, Text, List } from "@mantine/core";
import { Colors } from "../../../styles/colors";

type PrivacySectionProps = {
  title: string;
  description?: string;
  subsections?: Array<{
    subtitle: string;
    items: string[];
  }>;
  items?: string[];
};

export const PrivacySection = ({
  title,
  description,
  subsections,
  items,
}: PrivacySectionProps) => (
  <Box
    style={{
      padding: "2rem",
      borderRadius: "15px",
      background: "var(--glass-bg)",
      backdropFilter: "var(--glass-blur-sm)",
      border: "1px solid var(--purple-15)",
      marginBottom: "2rem",
    }}
  >
    <Title
      order={3}
      mb="xl"
      style={{
        color: "var(--purple2)",
      }}
    >
      {title}
    </Title>

    {description && <Text mb="xl">{description}</Text>}

    {subsections?.map(({ subtitle, items }) => (
      <Box key={subtitle} mb="xl">
        <Title order={4} mb="md">
          {subtitle}
        </Title>
        <List
          type="unordered"
          styles={{
            item: {
              color: Colors.WHITE,
              opacity: 0.9,
            },
          }}
        >
          {items.map((item) => (
            <List.Item key={item}>{item}</List.Item>
          ))}
        </List>
      </Box>
    ))}

    {items && (
      <List
        type="unordered"
        styles={{
          item: {
            color: Colors.WHITE,
            opacity: 0.9,
          },
        }}
      >
        {items.map((item) => (
          <List.Item key={item}>{item}</List.Item>
        ))}
      </List>
    )}
  </Box>
);
