import { Text } from "@mantine/core";

type TaglineProps = {
  largeScreen: boolean;
};

export const Tagline = ({ largeScreen }: TaglineProps) => (
  <Text
    size="xl"
    style={{
      textAlign: "center",
      maxWidth: largeScreen ? "600px" : "90%",
      color: "var(--white)",
      opacity: 0.9,
      margin: "0 auto",
    }}
  >
    Puzzles made social. Compete against friends, play new puzzles every day,
    and climb the leaderboard.
  </Text>
);
