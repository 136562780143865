import {
  AppShell as MantineAppShell,
  Container,
  Group,
  Anchor,
} from "@mantine/core";
import { PropsWithChildren, useState, useEffect } from "react";
import { useOs, useViewportSize } from "@mantine/hooks";
import { NavigationLinks } from "./NavigationLinks";
import { MobileMenu } from "./MobileMenu";
import { PageRoute } from "../../navigation/routes";
import classes from "../../styles/animations.module.css";

export const AppShell = ({ children }: PropsWithChildren) => {
  const os = useOs();
  const { width } = useViewportSize();
  const mobile = os === "ios" || os === "android" || width < 768;
  const [menuOpen, setMenuOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY < 10) {
        setIsVisible(true);
      } else if (currentScrollY > lastScrollY) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }

      if (menuOpen) {
        setMenuOpen(false);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY, menuOpen]);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
    <MantineAppShell
      p={"md"}
      h={"100vh"}
      header={{ height: 70 }}
      className={classes.fadeIn}
    >
      <MantineAppShell.Header
        style={{
          background: "var(--glass-bg)",
          backdropFilter: "var(--glass-blur-sm)",
          boxShadow: `0 1px 15px var(--purple-10)`,
          borderBottom: `1px solid var(--purple-15)`,
          transform: `translateY(${isVisible ? 0 : -100}px)`,
          transition: "transform 0.3s ease",
          position: "fixed",
          top: 0,
          width: "100%",
        }}
      >
        <Container size="lg" h="100%">
          <Group justify="space-between" align="center" h="100%" px="md">
            <Anchor href={PageRoute.HOME} underline="never">
              <img
                src="logoP.png"
                style={{
                  height: "45px",
                  width: "auto",
                }}
                alt="Puzzlit Logo"
              />
            </Anchor>

            {mobile ? (
              <MobileMenu
                opened={menuOpen}
                onToggle={toggleMenu}
                showMainLinks
              />
            ) : (
              <Group>
                <NavigationLinks variant="header" />
                <MobileMenu
                  opened={menuOpen}
                  onToggle={toggleMenu}
                  showMainLinks={false}
                />
              </Group>
            )}
          </Group>
        </Container>
      </MantineAppShell.Header>
      <MantineAppShell.Main>{children}</MantineAppShell.Main>
    </MantineAppShell>
  );
};

export default AppShell;
