export type TermsSection = {
  title: string;
  description?: string;
  subsections?: Array<{
    subtitle: string;
    content: string;
  }>;
  content?: string;
};

export const TERMS_SECTIONS: TermsSection[] = [
  {
    title: "Acceptance of Terms",
    content: "By choosing to access or use Puzzlit, you are indicating your acceptance and agreement to comply with these Terms and Conditions. It is essential to carefully read and understand the terms outlined below, as they govern your use of the Puzzlit app. If you do not agree with any part of these terms, you may not use the app.",
  },
  {
    title: "User Accounts",
    subsections: [
      {
        subtitle: "Registration",
        content: "Account creation is not required but if done so, you agree to provide accurate and complete information during the registration process and to update such information to keep it accurate and current.",
      },
      {
        subtitle: "Account Security",
        content: "You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. Notify us immediately if you become aware of any unauthorized use of your account.",
      },
    ],
  },
  {
    title: "User Content",
    subsections: [
      {
        subtitle: "Posting Puzzles",
        content: "Puzzlit allows users to post completed puzzle scores. By posting these scores, you grant us a non-exclusive, worldwide, royalty-free license to use, modify, and display your score on our app.",
      },
      {
        subtitle: "Content Restrictions",
        content: "You agree not to comment on any content that is harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, invasive of another's privacy, or otherwise objectionable. Please refer to our privacy policy for any questions.",
      },
    ],
  },
  {
    title: "Interaction with Other Users",
    subsections: [
      {
        subtitle: "Comments and Likes",
        content: "Puzzlit allows users to comment on and like posted puzzles. Be respectful in your interactions. We reserve the right to remove any content or block users that violate these terms.",
      },
    ],
  },
  {
    title: "Statistics and Analytics",
    content: "Puzzlit provides statistics on average scores and the number of puzzles played. These statistics are aggregated and anonymized. We may use this data for improving the app and for research purposes.",
  },
  {
    title: "Termination",
    content: "We reserve the right to suspend or terminate your account at our sole discretion if you violate these terms.",
  },
  {
    title: "Changes to Terms",
    content: "We may update these terms from time to time. You will be notified of significant changes. By continuing to use Puzzlit after any modifications, you agree to the updated terms.",
  },
  {
    title: "Contact Information",
    content: "If you have any questions about these terms, please contact us at: admin@puzzlitapp.com",
  },
]; 