import { Title, Text, Box } from "@mantine/core";

export const ContactHero = () => (
  <Box>
    <Title
      ta="center"
      style={{
        fontSize: "3rem",
        marginBottom: "2rem",
        color: "var(--purple)",
      }}
    >
      Contact Us
    </Title>
    <Box
      style={{
        padding: "2rem",
        borderRadius: "15px",
        background: "var(--glass-bg)",
        backdropFilter: "var(--glass-blur-sm)",
        border: "1px solid var(--purple-15)",
        marginBottom: "3rem",
      }}
    >
      <Text
        size="lg"
        ta="center"
        style={{ maxWidth: "800px", margin: "0 auto" }}
      >
        Have a question or feedback? We'd love to hear from you! Fill out the
        form below and we'll get back to you as soon as possible.
      </Text>
    </Box>
  </Box>
);
